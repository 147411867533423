import React from 'react';

import Seo from 'src/components/seo';

import s from './styles.module.css'

const Content = ({ pageContext: { meta_description, content }}) => {
  return <div className={s['global']}>
    <Seo description={meta_description} />
    <div className={s['content']}>
      <div dangerouslySetInnerHTML={{ __html: content.html }} />
    </div>
  </div>
}

export default Content